import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

export default class extends Controller {
  static targets = []

  initialize() {
    new SlimSelect({
      select: this.element,
      settings: {
        placeholderText: 'Rechercher',
      }
    })
  }
}
